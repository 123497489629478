export default class MenuSide {
  constructor() {
    const menus = document.querySelectorAll('nav.menu')

    menus.forEach((menu) => {
      menu.addEventListener('click', (e) => {
        e.preventDefault()
        const target = e.target.closest('a')

        if (target) {
          const elem = document.querySelector('#' + target.dataset.id)
          if (elem) elem.scrollIntoView({ behavior: 'smooth' })
        }
      })
    })
  }
}
